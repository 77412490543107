import React, { useState, useEffect } from 'react';
import Header from './shared/Header1';
import Footer from './shared/Footer1';
import ScrollToTop from './shared/ScrollToTop';
import appConstant from './shared/constant/constant.json';
import axios from 'axios';
import { useParams } from "react-router-dom";
function Checkout() {
    const { slug } = useParams();
    const [ticket, setTicket] = useState([]);
    const [subTotal, setSubTotal] = useState('');
    const [discount, setDiscount] = useState('');
    const [totalAmount, setTotalAmount] = useState('');
    const [details, setDetails] = useState([{ name: '', email: '', phone: '' }]);
    const [couponValidation, setCouponValidation] = useState('');
    const [isLoader, setIsLoader] = useState(false);
    const handleDetailChange = (index, field, value) => {
        const updatedDetails = details.map((detail, i) =>
            i === index ? { ...detail, [field]: value } : detail
        );
        setDetails(updatedDetails);
    };
    // ticket api
    const params = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*"
    };
    const getTicketData = () => {
        axios.get(`${appConstant.API_URL}/c/ticket/${slug}`, params).then(res => {
            if (res?.data?.status === 200) {
                setTicket(res?.data?.data);
                setSubTotal(res?.data?.data.price);
                setTotalAmount(res?.data?.data.price);
            } else {
                setTicket([]);
            }
        })
            .catch(error => {

            });
    }
    const [resultMessage, setResultMessage] = useState('');
    const [inputValues, setInputValues] = useState({
        name: "",
        email: "",
        phone_number: "",
        college: "",
        address: "",
        state: "",
        city: "",
        zipcode: "",
        ticket_date: "14 December",
        number_of_tickets: 1
    });

    const [coupon, setCoupon] = useState({
        name: "",
        type: "",
        value: "",
        showCoupon: false,
        message: ""
    })
    const getTotalAmount = (numerOfTickets, discount) => {
        const subTotalData = numerOfTickets * ticket.price;
        setSubTotal(subTotalData);
        const total = subTotalData - discount;
        setTotalAmount(total);

    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValues({ ...inputValues, [name]: value });

        if (inputValues.ticket_date === "14-15 Dec") {
            setSubTotal(ticket.price * 2);
        }

        if (name === "number_of_tickets" && value !== '') {
            const ticketCount = Number(value);
            if (isNaN(ticketCount) || ticketCount < 1) return;

            // Calculate how many entries need to be added or removed
            const requiredDetailsCount = ticketCount - 1; // We need one less entry than the ticket count

            if (requiredDetailsCount > details.length) {
                // Add empty details for the new tickets
                setDetails([...details, ...Array(requiredDetailsCount - details.length).fill({ name: '', email: '', phone: '' })]);
            } else {
                // Remove extra details when ticket count is reduced
                setDetails(details.slice(0, requiredDetailsCount));
            }
            let totalDiscount = 0;
            if(coupon.showCoupon){
                if(coupon.type === "flat"){
                    totalDiscount = coupon.value;
                }else{
                    totalDiscount = (ticket.price*value) * (coupon.value / 100);
                }
            }
            setDiscount(totalDiscount);
            getTotalAmount(ticketCount, totalDiscount);
        }
    };



    const validateDetails = () => {
        for (let i = 0; i < details.length; i++) {
            const { name, email, phone } = details[i];
            if (!name || !email || !phone) {
                // Validation failed, show an error message
                alert(`Please fill in all fields for ticket ${i + 2}`);
                return false;  // Stop further execution
            }
        }
        return true;  // All fields are valid
    };
    const [selectedFile, setSelectedFile] = useState(null);
    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };
    const [validation, setValidation] = useState({
        name: "",
        email: "",
        phone_number: "",
        college: "",
        address: "",
        state: "",
        city: "",
        zipcode: "",
        ticket_date: "",
        number_of_tickets: "",
        student_id: ""
    });
    const checkValidation = () => {
        let errors = JSON.parse(JSON.stringify(validation));
        //name validation
        const alphabetCond = /^[a-zA-Z ]*$/;
        if (!inputValues.name) {
            errors.name = "Full name is required";
        } else if (!inputValues.name.match(alphabetCond)) {
            errors.name = "Please enter valid name";
        } else {
            errors.name = "";
        }
        // email validation
        const emailCond = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!inputValues.email) {
            errors.email = "Email is required";
        } else if (!inputValues.email.match(emailCond)) {
            errors.email = "Please enter valid email address";
        } else {
            errors.email = "";
        }
        //number validation
        const numberCond = /^[6-9]\d{9}$/;
        if (!inputValues.phone_number) {
            errors.phone_number = "Number is required";
        } else if (!inputValues.phone_number.match(numberCond)) {
            errors.phone_number = "Please enter 10 digit phone number";
        } else {
            errors.phone_number = "";
        }
        //college validation
        // if (!inputValues.college) {
        //     errors.college = "College is required";
        // } else {
        //     errors.college = "";
        // }
        //address validation
        // if (!inputValues.address) {
        //     errors.address = "Address is required";
        // } else {
        //     errors.address = "";
        // }
        //state validation
        // if (!inputValues.state) {
        //     errors.state = "State is required";
        // } else {
        //     errors.state = "";
        // }
        // //city validation
        // if (!inputValues.city) {
        //     errors.city = "City is required";
        // } else {
        //     errors.city = "";
        // }
        //zipcode validation
        // const zipcodeCond = /^\d{6}$/; // Regular expression to match exactly 6 digits
        // if (!inputValues.zipcode) {
        //     errors.zipcode = "Zipcode is required";
        // } else if (!zipcodeCond.test(inputValues.zipcode)) {
        //     errors.zipcode = "Please enter a 6-digit number for the zipcode";
        // } else {
        //     errors.zipcode = "";
        // }
        //ticket date validation
        if (!inputValues.ticket_date) {
            errors.ticket_date = "Date is required";
        } else {
            errors.ticket_date = "";
        }
        //number of tickets validation
        if (!inputValues.number_of_tickets) {
            errors.number_of_tickets = "Ticket Number is required";
        } else {
            if(inputValues.number_of_tickets > 1){
                validateDetails();
            }
            errors.number_of_tickets = "";
        }
        //student id validation
        if (!selectedFile) {
            errors.student_id = "Student Id is required";
        } else {
            errors.student_id = "";
        }
        setValidation(errors);

        if (ticket.is_student_pass === '1') {
            if (errors.name === '' && errors.email === '' && errors.phone_number === '' && errors.college === '' && errors.state === '' && errors.city === '' && errors.zipcode === '' && errors.ticket_date === '' && errors.student_id === '') {
                return true;
            } else {
                return false;
            }
        } else {
            // if (errors.name === '' && errors.email === '' && errors.phone_number === '' && errors.address === '' && errors.state === '' && errors.city === '' && errors.zipcode === '' && errors.ticket_date === '' && errors.number_of_tickets === '') {
            //     return true;
            // } else {
            //     return false;
            // }
            if (errors.name === '' && errors.email === '' && errors.phone_number === '' && errors.ticket_date === '' && errors.number_of_tickets === '') {
                return true;
            } else {
                return false;
            }
        }
    };
    const proceedToPay = (e) => {
        e.preventDefault();
        const isSuccess = checkValidation();
        if (isSuccess) {
            initiateRazorpayPayment();
        }
    }
    useEffect(() => {
        getTicketData();
    }, []);
    const initiateRazorpayPayment = async () => {
        setIsLoader(true);
        const orderData = new FormData();
        orderData.append('name', inputValues.name);
        orderData.append('phone_number', inputValues.phone_number);
        orderData.append('email', inputValues.email);
        orderData.append('address', inputValues.address);
        orderData.append('state', inputValues.state);
        orderData.append('city', inputValues.city);
        orderData.append('zip_code', inputValues.zipcode);
        orderData.append('ticket_date', inputValues.ticket_date);
        orderData.append('number_of_tickets', inputValues.number_of_tickets);
        orderData.append('ticket', JSON.stringify(ticket));
        orderData.append('coupon', JSON.stringify(coupon));
        orderData.append('additional_tickets', JSON.stringify(details));
        const { data } = await axios.post(`${appConstant.API_URL}/c/create_order`, orderData, params);
        if(data.status === 200) {
            const parsedData = JSON.parse(data.data);
            const options = {
                key: appConstant.RAZORPAY_KEY, // Replace with your Razorpay key
                amount: parsedData.amount,
                currency: parsedData.currency,
                order_id: parsedData.id,
                handler: function (response) {
                    console.log('Payment Success', response);
                    const orderDetails = new FormData();
                    orderDetails.append('payment_id', response.razorpay_payment_id);
                    orderDetails.append('order_id', parsedData.id);
                    orderDetails.append('signature', response.razorpay_signature);
                    // Call backend to verify payment and create booking record
                    axios.post(`${appConstant.API_URL}/c/verify_payment`, orderDetails, params).then(res => {
                        if(res?.data?.status === 200){
                            window.location.href = '/payment-message/'+ parsedData.id;
                        }else{
                            alert('Payment Verification Failed!');
                            setIsLoader(false);
                        }
                    }).catch(() => {
                        setIsLoader(false);
                        alert('Payment Verification Failed!');
                    });
                },
                prefill: {
                    name: inputValues.name,
                    email: inputValues.email,
                    contact: inputValues.phone_number
                }
            };
            const razorpay = new window.Razorpay(options);
            razorpay.open();
        }else{
            alert(data.message);
        }
    };
    const checkCoupon = () => {
        const couponData = new FormData();
        couponData.append('coupon', coupon.name);
        axios.post(`${appConstant.API_URL}/c/coupon`, couponData, params)
            .then(res => {
                if (res?.data?.status === 200) {
                    const data = {
                        name: res?.data?.data?.name,
                        type: res?.data?.data?.type,
                        value: res?.data?.data?.amount,
                        showCoupon: true,
                        message: ''
                    }
                    setCoupon(data)
                    if(res?.data?.data?.type === 'flat'){
                        setDiscount(res?.data?.data?.amount);
                        getTotalAmount(inputValues.number_of_tickets, res?.data?.data?.amount);
                    }else{
                        const discountedAmount = subTotal * (res?.data?.data?.amount / 100);
                        setDiscount(discountedAmount);
                        getTotalAmount(inputValues.number_of_tickets, discountedAmount);
                    }
                }else {
                    const data = {
                        name: coupon.name,
                        type: '',
                        value: '',
                        showCoupon: false,
                        message: res?.data?.message
                    }
                    setCouponValidation(res?.data?.message);
                    setCoupon(data);

                }
            })
            .catch(error => {
            });
    }
    const handleCouponChange = (e) => {
        const { name, value } = e.target;
        setCoupon({ ...coupon, [name]: value });
        setCouponValidation('');
    }
      const checkCouponValidation = () => {
        let error = '';
        if (!coupon.name) {
        error = "Coupon is required";
        } else {
        error = "";
        }
        setCouponValidation(error);
        if(error === ''){
          return true;
        }else{
          return false;
        }
    };
    const applyCoupon = () => {
      const isSuccess = checkCouponValidation();
      if(isSuccess) {
          checkCoupon();
      }
    }
    return (
        <>
            <ScrollToTop />
            <Header />
            {isLoader ? (
                                <div className="loader-box" style={{flexDirection: 'column'}}><span class="loader"></span><h3 style={{textAlign: 'center'}}>Please do not close this page or refresh the page.</h3></div>
                            ) : (
            <div className='ticketsPage'>
                <form onSubmit={(e) => { proceedToPay(e) }}>
                    <div className='ticketsPage-flex'>
                        <div className='ticketsPage-column-5'>
                            <div className='information-box'>
                                <div className='info-header'>
                                    <img className='cinema' src={`${appConstant.CDN_URL}/assets/images/cinema.png`} alt='icon' />
                                    <div>
                                        <p className='paragraph ff-bold mb-0'>M-Ticket</p>
                                        <p className='notes mb-0'>Use Your Phone as a Ticket</p>
                                    </div>
                                </div>
                                <div className='info-points'>
                                    <p className='paragraph ff-bold'>Information</p>
                                    <p className='notes'>Use coupon code <u>Jashnaa10</u> and get 10% off on your booking.</p>
                                    <p className='notes'>No physical ticket(s) are required to enter the venue.</p>
                                    {ticket.is_student_pass === '1' ? <p className='notes orange-text'>Valid Student ID must for all students*</p> : null}
                                </div>
                            </div>

                            {resultMessage && <p className='paragraph text-center ff-bold green-text mb-2'>{resultMessage}</p>}
                            <div className='row'>
                                <div className='col-sm-6 form-mb'>
                                    <div className='input-groups'>
                                        <label className='contact-label'><img className='label-icons' src={`${appConstant.CDN_URL}/assets/images/user.png`} alt="icons" /></label>
                                        <input className='contact-input' type="text" name="name" placeholder="Name*" value={inputValues.name} onChange={(e) => handleChange(e)} />
                                    </div>
                                    {validation.name && <p className='paragraph red-text mt-2'>{validation.name}</p>}
                                </div>
                                <div className='col-sm-6 form-mb'>
                                    <div className='input-groups'>
                                        <label className='contact-label'><img className='label-icons' src={`${appConstant.CDN_URL}/assets/images/email.png`} alt="icons" /></label>
                                        <input className='contact-input' type="text" name="email" placeholder="Email Address*" value={inputValues.email} onChange={(e) => handleChange(e)} />
                                    </div>
                                    {validation.email && <p className='paragraph red-text mt-2'>{validation.email}</p>}
                                </div>
                                <div className='col-sm-6 form-mb'>
                                    <div className='input-groups'>
                                        <label className='contact-label'><img className='label-icons' src={`${appConstant.CDN_URL}/assets/images/phone.png`} alt="icons" /></label>
                                        <input className='contact-input' type="text" name="phone_number" placeholder="Phone*" value={inputValues.phone_number} onChange={(e) => handleChange(e)} />
                                    </div>
                                    {validation.phone_number && <p className='paragraph red-text mt-2'>{validation.phone_number}</p>}
                                </div>
                                {/* {ticket.is_student_pass === '1' ?
                                    <div className='col-sm-6 form-mb'>
                                        <div className='input-groups'>
                                            <label className='contact-label'><img className='label-icons' src={`${appConstant.CDN_URL}/assets/images/home.png`} alt="icons" /></label>
                                            <input className='contact-input' type="text" name="college" placeholder="College/Institute*" value={inputValues.college} onChange={(e) => handleChange(e)} />
                                        </div>
                                        {validation.college && <p className='paragraph red-text mt-2'>{validation.college}</p>}
                                    </div>
                                    :
                                    <div className='col-sm-6 form-mb'>
                                        <div className='input-groups'>
                                            <label className='contact-label'><img className='label-icons' src={`${appConstant.CDN_URL}/assets/images/home.png`} alt="icons" /></label>
                                            <input className='contact-input' type="text" name="address" placeholder="Address*" value={inputValues.address} onChange={(e) => handleChange(e)} />
                                        </div>
                                        {validation.address && <p className='paragraph red-text mt-2'>{validation.address}</p>}
                                    </div>
                                } */}
                                {/* <div className='col-sm-6 form-mb'>
                                    <div className='input-groups'>
                                        <label className='contact-label'><img className='label-icons' src={`${appConstant.CDN_URL}/assets/images/pin.png`} alt="icons" /></label>
                                        <input className='contact-input' type="text" name="state" placeholder="State*" value={inputValues.state} onChange={(e) => handleChange(e)} />
                                    </div>
                                    {validation.state && <p className='paragraph red-text mt-2'>{validation.state}</p>}
                                </div>
                                <div className='col-sm-6 form-mb'>
                                    <div className='input-groups'>
                                        <label className='contact-label'><img className='label-icons' src={`${appConstant.CDN_URL}/assets/images/city.png`} alt="icons" /></label>
                                        <input className='contact-input' type="text" name="city" placeholder="City*" value={inputValues.city} onChange={(e) => handleChange(e)} />
                                    </div>
                                    {validation.city && <p className='paragraph red-text mt-2'>{validation.city}</p>}
                                </div>
                                <div className='col-sm-6 form-mb'>
                                    <div className='input-groups'>
                                        <label className='contact-label'><img className='label-icons' src={`${appConstant.CDN_URL}/assets/images/edit.png`} alt="icons" /></label>
                                        <input className='contact-input' type="text" name="zipcode" placeholder="Zip/Postal Code*" value={inputValues.zipcode} onChange={(e) => handleChange(e)} />
                                    </div>
                                    {validation.zipcode && <p className='paragraph red-text mt-2'>{validation.zipcode}</p>}
                                </div> */}
                                {/* {ticket.is_student_pass == 1 ?
                                    <div className='col-sm-6 form-mb'>
                                        <div className='input-groups'>
                                            <input className='contact-input' type="file" name="student_id" onChange={(e) => handleFileChange(e)} />
                                        </div>
                                        <p className='red-text small-text ff-bold'>(Upload a valid Student Id*)</p>
                                        {validation.student_id && <p className='paragraph red-text mt-2'>{validation.student_id}</p>}
                                    </div>
                                    : ''} */}
                                <div className='col-sm-6 form-mb'>
                                    <div className='input-groups'>
                                        <label className='contact-label'><img className='label-icons' src={`${appConstant.CDN_URL}/assets/images/edit.png`} alt="icons" /></label>
                                        <select name="ticket_date" className='contact-input' value={inputValues.ticket_date} onChange={(e) => handleChange(e)}>
                                            <option value="14 December">14 December</option>
                                            <option value="15 December">15 December</option>
                                        </select>
                                    </div>
                                    {validation.ticket_date && <p className='paragraph red-text mt-2'>{validation.ticket_date}</p>}
                                </div>
                                {ticket.is_student_pass === '0' ?
                                    <div className='col-sm-6 form-mb'>
                                        <div className='input-groups'>
                                            <label className='contact-label'><img className='label-icons' src={`${appConstant.CDN_URL}/assets/images/edit.png`} alt="icons" /></label>
                                            <input className='contact-input' type="number" name="number_of_tickets" min='1' placeholder="Number of Tickets" value={inputValues.number_of_tickets} onChange={(e) => handleChange(e)} />
                                        </div>
                                        {validation.number_of_tickets && <p className='paragraph red-text mt-2'>{validation.number_of_tickets}</p>}
                                    </div>
                                    : ''}
                            </div>

                            {inputValues.number_of_tickets > 1 && (
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Email ID</th>
                                                <th>Phone Number</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {details.map((detail, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <input className='table-input' type="text" name="" placeholder="Enter Name" onChange={(e) => handleDetailChange(index, 'name', e.target.value)} value={detail.name} />
                                                    </td>
                                                    <td>
                                                        <input className='table-input' type="text" name="" placeholder="Enter Email ID" onChange={(e) => handleDetailChange(index, 'email', e.target.value)} value={detail.email} />
                                                    </td>
                                                    <td>
                                                        <input className='table-input' type="text" name="" placeholder="Enter Phone Number" onChange={(e) => handleDetailChange(index, 'phone', e.target.value)} value={detail.phone} />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                        <div className='ticketsPage-column-6'>
                            <div className='payment-details'>
                                <div className='ticket-detail-card'>
                                    <div className='detail-card-flex'>
                                        <div>
                                            <h6 className='paragraph ff-bold tt-uppercase mb-0 theme-blue-text'> {ticket.title} TICKET</h6>
                                            <h6 className='paragraph mb-0'><small>{ticket.is_student_pass === '1' ? '1 Ticket' : inputValues.number_of_tickets ? inputValues.number_of_tickets + ' Ticket(s)' : '1 Ticket'} </small></h6>
                                        </div>
                                        <div>
                                            <h6 className='paragraph ff-bold tt-uppercase mb-0 orange-text'>INR  {ticket.price}/DAY</h6>
                                        </div>
                                    </div>
                                    <div className='detail-card-flex'>
                                        <div>
                                            <h6 className='paragraph mb-0'>
                                                {inputValues.ticket_date} 2024<br />
                                                12PM - 11PM<br />
                                                Agra
                                            </h6>
                                        </div>
                                    </div>
                                    {/* <div className='detail-card-flex'>
                                    <div>
                                        <h6 className='paragraph mb-0'>Category <span className='tt-uppercase'>{ticket.title}T</span>({ticket.price}): </h6>
                                    </div>
                                    <div>
                                        <h6 className='paragraph mb-0'>{ticket.is_student_pass === '1' ? '1 Ticket' : inputValues.number_of_tickets + ' Ticket(s)'}</h6>
                                    </div>
                                </div> */}
                                </div>
                                <div className='ticket-detail-card border-transparent'>
                                    <div className='detail-card-flex'>
                                        <div>
                                            <h6 className='paragraph mb-0'>Sub Total:</h6>
                                        </div>
                                        <div>
                                            <h6 className='paragraph mb-0'>INR {subTotal} </h6>
                                        </div>
                                    </div>
                                    {discount ?
                                        <div className='detail-card-flex'>
                                            <div>
                                                <h6 className='paragraph mb-0'>Discount:</h6>
                                            </div>
                                            <div>
                                                <h6 className='paragraph mb-0'>INR {discount}</h6>
                                            </div>
                                        </div>
                                        : null
                                    }
                                    <div className='detail-card-flex confirm'>
                                        <div>
                                            <h6 className='paragraph mb-0 ff-bold'>Total Amount:</h6>
                                        </div>
                                        <div>
                                            <h6 className='paragraph mb-0  ff-bold'>INR {totalAmount}</h6>
                                        </div>
                                    </div>
                                    <div className='d-flex'>
                                        <img className='note-info' src={`${appConstant.CDN_URL}/assets/images/info.png`} alt='info' />
                                        <p className='notes'>By proceeding, I express my consent to complete this transaction.</p>
                                    </div>
                                    {!coupon.showCoupon ?
                                            <div>
                                                <div className='coupon'>
                                                    <input type="text" name='name' value={coupon.name} onChange={(e) => {handleCouponChange(e)}} placeholder='Enter Coupon Code' />
                                                    <button type="button" className='orange-button button-small d-inline-block m-0' onClick={() => applyCoupon()}>Apply</button>
                                                </div>
                                                {couponValidation && <p className='red-text mt-2'>{couponValidation}</p>}
                                            </div>
                                        : null}
                                    <button className='orange-button button-small d-inline-block'><img className='button-icon' src={`${appConstant.CDN_URL}/assets/images/plane.png`} alt="icons" />Pay Now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <ul className='footer-links'>
                    <li className='icons'><a href={`${appConstant.FACEBOOK_URL}`} target='_blank' rel="noreferrer"><img src={`${appConstant.CDN_URL}/assets/images/facebook.png`} alt='bg' /></a></li>
                    <li className='icons'><a href={`${appConstant.TWITTER_URL}`} target='_blank' rel="noreferrer"><img src={`${appConstant.CDN_URL}/assets/images/twitter.png`} alt='bg' /></a></li>
                    <li className='icons'><a href={`${appConstant.INSTAGRAM_URL}`} target='_blank' rel="noreferrer"><img src={`${appConstant.CDN_URL}/assets/images/instagram.png`} alt='bg' /></a></li>
                    <li className='icons'><a href={`${appConstant.YOUTUBE_URL}`} target='_blank' rel="noreferrer"><img src={`${appConstant.CDN_URL}/assets/images/youtube.png`} alt='bg' /></a></li>
                </ul>
            </div>)
            }
            <Footer />
        </>
    );
}
export default Checkout;