import React ,{ useState, useEffect }from 'react';
import Header from './shared/Header1';
import Footer from './shared/Footer1';
import ScrollToTop from './shared/ScrollToTop';
import Card from './shared/Card';
import axios from 'axios';
import Loader from './shared/Loader';
import appConstant from './shared/constant/constant.json';
import {Helmet} from "react-helmet";

function Artists() {
    const [artist, setArtist] = useState([]);
    const [isLoader, setIsLoader] = useState(true);
    const [getSEO, setGetSEO] = useState([]);

    const params = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }

    const getSEOData = () => {
        axios
          .get(`${appConstant.API_URL}/c/page_meta/artists`, params)
          .then((res) => {
            if (res.data.status === 200) {
                setGetSEO(res.data.data);
            }
          })
          .catch((error) => {
          });
    };
    const getArtistData = () => {
        axios.get(`${appConstant.API_URL}/c/artist`, params).then(res => {
              setIsLoader(false);
            if (res?.data?.status === 200) {
                setArtist(res?.data?.data);
            } else {
                setArtist([]);
            }
        })
        .catch(error => {
              setIsLoader(true);
        });
    }
    useEffect(() => {
        getArtistData();
        getSEOData();
    },[]);
    return (
        <>
        <Helmet>
            <title>{getSEO?.meta_title || 'Artists'}</title>
            <meta name="description" content={getSEO?.meta_description} />
            <meta name="keywords" content={getSEO?.meta_keyword} />
            <meta
                property="og:title"
                content={getSEO?.meta_title}
            />
            <meta
                property="og:image"
                content={`${appConstant.CDN_URL}/assets/images/logo-black.png`}
            />
        </Helmet>
        <ScrollToTop/>
        <Header/>
        <section className='tickets d-block internal-page'>
                <h5 className='cursive-heading text-center'>our</h5>
                <h3 className='heading text-center mb-0'>Favorite Artists</h3>
                <p className='paragraph text-center'>on the festival will</p>
                {isLoader ? (
                      <Loader />
                      ) : (
                      <>
                {artist.length > 0 && 
                <div className='ticket-flex'>
                    {artist.map((res, i)=>{
                        return (
                            <Card title={res.title} description={res.description} url={res.url} image={res.image} key={'artist' + i}/>
                        )
                    })}                  
                </div>
                }
                </>
              )}
        </section>
        <Footer/>
        </>
    );
}
export default Artists;