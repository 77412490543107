import React, {useState, useEffect} from 'react';
import Header from './shared/Header1';
import Footer from './shared/Footer1';
import ScrollToTop from './shared/ScrollToTop';
import axios from 'axios';
import Loader from './shared/Loader';
import appConstant from './shared/constant/constant.json';
import {Helmet} from "react-helmet";

function ContactUs() {
    const [resultMessage, setResultMessage] = useState('');
    const [inputValues, setInputValues] = useState({
        name : "",
        phone_number : "",
        email : "",
        subject : "",
        message : "",
    });
    const handleChange = (e) => {
        const {name, value} = e.target;
        setInputValues({...inputValues, [name]: value});
    }
    const [validation, setValidation] = useState({
        name : "",
        phone_number : "",
        email : "",
        subject : "",
        message : "",
    });
    const checkValidation = () => {
        let errors = JSON.parse(JSON.stringify(validation));

        //first validation
        const alphabetCond = /^[a-zA-Z ]*$/;
        if (!inputValues.name) {
        errors.name = "Full name is required";
        } else if (!inputValues.name.match(alphabetCond)) {
        errors.name = "Please enter valid name";
        } else {
        errors.name = "";
        }
        //number validation
        const numberCond = /^[6-9]\d{9}$/;
        if (!inputValues.phone_number) {
        errors.phone_number = "Number is required";
        } else if (!inputValues.phone_number.match(numberCond)) {
        errors.phone_number = "Please enter 10 digit phone number";
        } else {
        errors.phone_number = "";
        }
        // email validation
        const emailCond = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!inputValues.email) {
          errors.email = "Email is required";
        } else if (!inputValues.email.match(emailCond)) {
          errors.email = "Please enter valid email address";
        } else {
          errors.email = "";
        }
        //subject validation
        if (!inputValues.subject) {
        errors.subject = "Subject is required";
        }else {
        errors.subject = "";
        }
        //message validation
        if (!inputValues.message) {
        errors.message = "Message is required";
        } else {
        errors.message = "";
        }

        setValidation(errors);

        if(errors.name === '' && errors.phone_number === '' && errors.email === '' && errors.subject === '' && errors.message === ''){
          return true;
        }else{
          return false;
        }
    };
    const contactUs = (e) => {
        e.preventDefault();
        const isSuccess = checkValidation();
        if(isSuccess) {
            const contactData = new FormData();
            contactData.append('name', inputValues.name);
            contactData.append('phone_number', inputValues.phone_number);
            contactData.append('email', inputValues.email);
            contactData.append('subject', inputValues.subject);
            contactData.append('message', inputValues.message);
            const params = {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            };
            axios.post(`${appConstant.API_URL}/c/contact`, contactData, params)
                .then(res => {
                    if (res?.data?.status === 200) {
                        setResultMessage(res?.data?.message);
                        setInputValues({
                            name : "",
                            phone_number : "",
                            email : "",
                            subject : "",
                            message : "",
                        }); 
                        setTimeout(() => {
                            setResultMessage('');
                        }, 5000);
                    }else {
                        setResultMessage(res?.data?.message);
                    }
                })
                .catch(error => {
                });
        }
    }

    const [faq, setFaq] = useState([]);
    const [isLoader, setIsLoader] = useState(true);
    const [getSEO, setGetSEO] = useState([]);

    const params = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }

    const getSEOData = () => {
        axios
          .get(`${appConstant.API_URL}/c/page_meta/contact`, params)
          .then((res) => {
            if (res.data.status === 200) {
                setGetSEO(res.data.data);
            }
          })
          .catch((error) => {
          });
    };
    const getFaqData = () => {
        axios.get(`${appConstant.API_URL}/c/faq`, params).then(res => {
              setIsLoader(false);
            if (res?.data?.status === 200) {
                setFaq(res?.data?.data);
            } else {
                setFaq([]);
            }
        })
        .catch(error => {
              setIsLoader(true);
        });
    }
    useEffect(() => {
        getFaqData();
        getSEOData();
    },[]);
    return (
        <>
        <Helmet>
            <title>{getSEO?.meta_title || 'Contact Us'}</title>
            <meta name="description" content={getSEO?.meta_description} />
            <meta name="keywords" content={getSEO?.meta_keyword} />
            <meta
                property="og:title"
                content={getSEO?.meta_title}
            />
            <meta
                property="og:image"
                content={`${appConstant.CDN_URL}/assets/images/logo-black.png`}
            />
        </Helmet>
        <ScrollToTop/>
        <Header/>
        <div className='contactPage'>
            <div className='contact-flex'>
                <div className='contact-column-3'>
                    <h3 className='heading'>CONTACT DETAILS</h3>
                    <p className='paragraph'>1103, Tower F, Prateek <br/>Laurel, Noida sector 120, <br/>201307</p>
                    <p className='paragraph'><a className='td-underline' href="mailto:{`${appConstant.EMAIL_URL}`}">{`${appConstant.SALE_EMAIL_URL}`}</a></p>
                    <p className='paragraph ff-bold'>+91 {`${appConstant.CONTACT_URL}`}</p>
                    {/* <ul className='footer-links'>
                        <li className='icons'><a href={`${appConstant.FACEBOOK_URL}`} target='_blank' rel="noreferrer"><img src={`${appConstant.CDN_URL}/assets/images/facebook.png`} alt='bg'/></a></li>
                        <li className='icons'><a href={`${appConstant.TWITTER_URL}`} target='_blank' rel="noreferrer"><img src={`${appConstant.CDN_URL}/assets/images/twitter.png`} alt='bg'/></a></li>
                        <li className='icons'><a href={`${appConstant.INSTAGRAM_URL}`} target='_blank' rel="noreferrer"><img src={`${appConstant.CDN_URL}/assets/images/instagram.png`} alt='bg'/></a></li>
                        <li className='icons'><a href={`${appConstant.YOUTUBE_URL}`} target='_blank' rel="noreferrer"><img src={`${appConstant.CDN_URL}/assets/images/youtube.png`} alt='bg' /></a></li>
                    </ul> */}
                </div>
                <div className='contact-column-4'>
                    <form onSubmit={(e) => {contactUs(e)}}>
                        {resultMessage && <p className='paragraph text-center ff-bold green-text mb-2'>{resultMessage}</p>}
                        <div className='row'>
                            <div className='col-sm-6 form-mb'>
                                <div className='input-groups'>
                                    <label className='contact-label'><img className='label-icons' src={`${appConstant.CDN_URL}/assets/images/user.png`} alt="icons"/></label>
                                    <input className='contact-input' type="text" name="name" placeholder="Name*" value={inputValues.name} onChange={(e) => handleChange(e)}/>                                    
                                </div>
                                {validation.name && <p className='paragraph red-text mt-2'>{validation.name}</p>}
                            </div>
                            <div className='col-sm-6 form-mb'>
                                <div className='input-groups'>
                                    <label className='contact-label'><img className='label-icons' src={`${appConstant.CDN_URL}/assets/images/email.png`} alt="icons"/></label>
                                    <input className='contact-input' type="text" name="email" placeholder="Email Address*" value={inputValues.email} onChange={(e) => handleChange(e)}/>
                                </div>
                                {validation.email && <p className='paragraph red-text mt-2'>{validation.email}</p>}
                            </div>
                            <div className='col-sm-6 form-mb'>
                                <div className='input-groups'>
                                    <label className='contact-label'><img className='label-icons' src={`${appConstant.CDN_URL}/assets/images/phone.png`} alt="icons"/></label>
                                    <input className='contact-input' type="text" name="phone_number" placeholder="Phone*" value={inputValues.phone_number} onChange={(e) => handleChange(e)}/>
                                </div>
                                {validation.phone_number && <p className='paragraph red-text mt-2'>{validation.phone_number}</p>}
                            </div>
                            <div className='col-sm-6 form-mb'>
                                <div className='input-groups'>
                                    <label className='contact-label'><img className='label-icons' src={`${appConstant.CDN_URL}/assets/images/award.png`} alt="icons"/></label>
                                    <input className='contact-input' type="text" name="subject" placeholder="Subject*" value={inputValues.subject} onChange={(e) => handleChange(e)}/>
                                </div>
                                {validation.subject && <p className='paragraph red-text mt-2'>{validation.subject}</p>}                                
                            </div>
                            <div className='col-sm-12 form-mb'>
                                <div className='input-groups'>
                                    <label className='contact-label'><img className='label-icons' src={`${appConstant.CDN_URL}/assets/images/edit.png`} alt="icons"/></label>
                                    <textarea className='contact-input' placeholder="How can we help you? Feel free to get in touch!" name="message" value={inputValues.message} onChange={(e) => handleChange(e)}></textarea>
                                </div>
                                {validation.message && <p className='paragraph red-text mt-2'>{validation.message}</p>}
                            </div>
                        </div>
                        <div className='contact-flex-buttons'>
                            <button className='orange-button d-inline-block'><img className='button-icon' src={`${appConstant.CDN_URL}/assets/images/plane.png`} alt="icons" />GET IN TOUCH</button>
                            <label className='paragraph'><input className='checkbox' type="checkbox"  /> I agree that my data is collected and stored.</label>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div className='faqPage'>
            <h3 className='heading text-center'>FAQ</h3>
            {isLoader ? (
                      <Loader />
                      ) : (
                      <>
                {faq.length > 0 && 
            <div className='faq-container'>
                {faq.map((res, i)=>{
                    return (
                        <div className="faq-drawer">
                            <input className="faq-drawer__trigger" id={"faq-drawer" + i} type="checkbox" /><label className="faq-drawer__title" for={"faq-drawer" + i}>{res.title}</label>
                            <div className="faq-drawer__content-wrapper">
                                <div className="faq-drawer__content">
                                <p className='paragraph'>{res.description}</p>
                                </div>
                            </div>
                        </div>
                    )
                })} 
                
            </div>
            }
            </>
          )}
        </div>
        <Footer/>
        </>
    );
}
export default ContactUs;