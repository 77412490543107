import React, {useState} from 'react';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import appConstant from './constant/constant.json';
import axios from 'axios';
import {toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Header() {
    const [newsletter, setNewsletter] = useState('');
    const handleChange = (e) => {
        const { value } = e.target;
        setNewsletter(value);
    };
    const checkValidation = () => {
        let isValid = true;
        //newsletter validation
        const emailCond = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!newsletter) {
            isValid = false;
          toast.error("Email is required");
        } else if (!newsletter.match(emailCond)) {
            isValid = false;
          toast.error("Please enter valid email address");
        } else {
          isValid = true;
        }
       return isValid;
    };
    const subscribe = (e) => {
        e.preventDefault();
        const isSuccess = checkValidation();
        if (isSuccess) {    
            const newsletterData = new FormData();
            newsletterData.append('email', newsletter);
    
            axios.post(`${appConstant.API_URL}/c/newsletter`, newsletterData)
                .then(res => {
                    if (res?.data?.status === 200) {                       
                        toast.success(res?.data?.message)
                    } else {
                        toast.error(res?.data?.message)
                    }
                })
                .catch(error => {
                    // Handle error if POST request fails
                });
        }
    }
    return (
        <>
        <header className='header'>
            <nav className="navbar navbar-expand-xl">
                <Link className="navbar-brand" to="/"><img src={`${appConstant.CDN_URL}/assets/images/logo.png`} alt='jashnaa'/></Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar"><i className="fa-solid fa-bars"></i></button>
                <div className="collapse navbar-collapse" id="collapsibleNavbar">
                    <ul className="navbar-nav ms-auto">                             
                        <li className="nav-item">
                            <HashLink className="nav-link" to="/#home">Home</HashLink>
                        </li>                                        
                        <li className="nav-item">
                            <HashLink className="nav-link" to="/buy-tickets">Buy Tickets</HashLink>
                        </li>
                        <li className="nav-item">
                            <HashLink className="nav-link" to="/#food-and-drink">Music</HashLink>
                        </li>
                        <li className="nav-item">
                            <HashLink className="nav-link" to="/#food-and-drink">Food & Drinks</HashLink>
                        </li>  
                        <li className="nav-item">
                            <HashLink className="nav-link" to="/#schedual">Schedule</HashLink>
                        </li> 
                        <li className="nav-item dropdown">
                            <div className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown">Partner with us</div>
                            <ul className="dropdown-menu">
                                <li><Link className="dropdown-item" to="/sponsor">Sponsor</Link></li>
                                <li><Link className="dropdown-item" to="/exhibitor">Exhibitor</Link></li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/contact-us">Contact us</Link>
                        </li> 
                    </ul>
                </div>
            </nav>
            <ul className='second-row'>
                <li className='icons'><a  href={`${appConstant.FACEBOOK_URL}`} target='_blank' rel="noreferrer"><img src={`${appConstant.CDN_URL}/assets/images/facebook.png`} alt='bg'/></a></li>
                <li className='icons'><a href={`${appConstant.TWITTER_URL}`} target="_blank" rel="noreferrer"><img src={`${appConstant.CDN_URL}/assets/images/twitter.png`} alt='bg'/></a></li>
                <li className='icons'><a href={`${appConstant.INSTAGRAM_URL}`} target="_blank" rel="noreferrer"><img src={`${appConstant.CDN_URL}/assets/images/instagram.png`} alt='bg'/></a></li>
                <li className='icons'><a href={`${appConstant.YOUTUBE_URL}`} target="_blank" rel="noreferrer"><img src={`${appConstant.CDN_URL}/assets/images/youtube.png`} alt='bg' /></a></li>
                <li>
                    <input type='text' name='search' className='search-bar' placeholder='EMAIL for SIGN UP TO OUR ENEWS' value={newsletter} onChange={(e) => handleChange(e)}/>
                    <button className='header-button' onClick={subscribe}>SUBSCRIBE NOW</button>
                </li>
            </ul>
        </header>
        <ToastContainer />
        </>
    );
}
export default Header;