import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import appConstant from './constant/constant.json';

function Header() {
    return (
        <header className='header header-1'>
            <nav className="navbar navbar-expand-xl">
                <Link className="navbar-brand" to="/"><img src={`${appConstant.CDN_URL}/assets/images/logo-black.png`} alt='jashnaa'/></Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar"><i className="fa-solid fa-bars"></i></button>
                <div className="collapse navbar-collapse" id="collapsibleNavbar">
                    <ul className="navbar-nav ms-auto">                             
                        <li className="nav-item">
                            <HashLink className="nav-link" to="/#home">Home</HashLink>
                        </li>                                        
                        <li className="nav-item">
                            <HashLink className="nav-link" to="/buy-tickets">Buy Tickets</HashLink>
                        </li>
                        <li className="nav-item">
                            <HashLink className="nav-link" to="/#food-and-drink">Music</HashLink>
                        </li>
                        <li className="nav-item">
                            <HashLink className="nav-link" to="/#food-and-drink">Food & Drinks</HashLink>
                        </li>  
                        <li className="nav-item">
                            <HashLink className="nav-link" to="/#schedual">Schedule</HashLink>
                        </li> 
                        <li className="nav-item dropdown">
                            <div className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown">Partner with us</div>
                            <ul className="dropdown-menu">
                                <li><Link className="dropdown-item" to="/sponsor">Sponsor</Link></li>
                                <li><Link className="dropdown-item" to="/exhibitor">Exhibitor</Link></li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/contact-us">Contact us</Link>
                        </li> 
                    </ul>
                </div>
            </nav>
        </header>
    );
}
export default Header;