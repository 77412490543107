import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import appConstant from './constant/constant.json';
import axios from 'axios';

function Footer() {
    const [resultMessage, setResultMessage] = useState('');
    const [newsletter, setNewsletter] = useState('');
    const handleChange = (e) => {
        const { value } = e.target;
        setNewsletter(value);
    };
    const [validation, setValidation] = useState({
        newsletter : "",
    });
    const checkValidation = () => {
        let errors = JSON.parse(JSON.stringify(validation));
        //newsletter validation
        const emailCond = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!newsletter) {
          errors.newsletter = "Email is required";
        } else if (!newsletter.match(emailCond)) {
          errors.newsletter = "Please enter valid email address";
        } else {
          errors.newsletter = "";
        }
        setValidation(errors);

        if(errors.newsletter === ''){
          return true;
        }else{
          return false;
        }
    };
    const subscribe = (e) => {
        e.preventDefault();
        const isSuccess = checkValidation();
        if (isSuccess) {
            setResultMessage('');
            setValidation({ newsletter: '' });
    
            const newsletterData = new FormData();
            newsletterData.append('email', newsletter);
    
            axios.post(`${appConstant.API_URL}/c/newsletter`, newsletterData)
                .then(res => {
                    if (res?.data?.status === 200) {
                        setResultMessage(res?.data?.message);
                        setNewsletter('');
                        // Reset resultMessage after 5 seconds
                        setTimeout(() => {
                            setResultMessage('');
                        }, 5000);
                    } else {
                        const data = validation;
                        data.newsletter = res?.data?.message;
                        setValidation(data);
                    }
                })
                .catch(error => {
                    // Handle error if POST request fails
                });
        }
    }
    return (
        <footer className='footer'>
            <h5 className='cursive-heading text-center'>get the</h5>
            <h3 className='heading text-center text-white'>best blog stories<br/>into your inbox!</h3>
            {resultMessage && <p className='paragraph ff-bold text-center green-text mb-2'>{resultMessage}</p>}
            {validation.newsletter && <p className='paragraph text-center red-text'>{validation.newsletter}</p>}
            <div className='newsletter-box'>
                <input className='newletter-input' type="text" placeholder='YOUR EMAIL' value={newsletter} onChange={(e) => handleChange(e)}/>
                <button className='Newsletter-button' onClick={subscribe}>SUBSCRIBE NOW</button>
            </div>
            {/* <div className='instagram-feed-container'>
                <img className='imstagram-feed' src={`/assets/images/food1.png`} alt=''/>
                <img className='imstagram-feed' src={`/assets/images/food1.png`} alt=''/>
                <img className='imstagram-feed' src={`/assets/images/food1.png`} alt=''/>
                <img className='imstagram-feed' src={`/assets/images/food1.png`} alt=''/>
                <img className='imstagram-feed' src={`/assets/images/food1.png`} alt=''/>
                <img className='imstagram-feed' src={`/assets/images/food1.png`} alt=''/>
                <div className='instagram-overlay'>
                    <a href='/' target='_blank' className='instagram-text'><img src={`${appConstant.CDN_URL}/assets/images/instagram-white.png`} alt='instagram'/> FOLLOW ME</a>
                </div>
            </div> */}
            {/* <label className='paragraph text-center text-white d-block'><input className='checkbox' type="checkbox"  /> I agree to the Privacy Policy.</label> */}
            <div className='footer-flex'>
                <p className='copyright'>© 2024 JASHNAA All Rights Reserved.</p>
                <ul className='footer-links'>
                    <Link className='links' to='/'>HOME</Link> 
                    {/* <Link className='links' to='/'>PLAN YOUR VISIT</Link>  */}
                    <Link className='links' to='/buy-tickets'>TICKETS</Link> 
                    <Link className='links' to='/contact-us'>CONTACT US</Link> 
                </ul>
                <ul className='footer-links'>
                    <li className='icons'><a href={`${appConstant.FACEBOOK_URL}`} rel="noreferrer" target='_blank'><img src={`${appConstant.CDN_URL}/assets/images/facebook-white.png`} alt='facebook'/></a></li>
                    <li className='icons'><a href={`${appConstant.TWITTER_URL}`} target='_blank' rel="noreferrer"><img src={`${appConstant.CDN_URL}/assets/images/twitter-white.png`} alt='twitter'/></a></li>
                    <li className='icons'><a href={`${appConstant.INSTAGRAM_URL}`} target='_blank' rel="noreferrer"><img src={`${appConstant.CDN_URL}/assets/images/instagram-white.png`} alt='instagram'/></a></li>
                    <li className='icons'><a href={`${appConstant.YOUTUBE_URL}`} target='_blank' rel="noreferrer"><img src={`${appConstant.CDN_URL}/assets/images/youtube-white.png`}alt='youtube' /></a></li>
                </ul>
            </div>
        </footer>
    );
}
export default Footer;