import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from './shared/Header1';
import Footer from './shared/Footer1';
import ScrollToTop from './shared/ScrollToTop';
import appConstant from './shared/constant/constant.json';
import axios from 'axios';
import {Helmet} from "react-helmet";

function Sponsor() {
    const [resultMessage, setResultMessage] = useState('');
    const [showPdf, setShowPdf] = useState(false);
    const [inputValues, setInputValues] = useState({
        name: "",
        email: "",
        phone_number: "",
        jobTitle: "",
        company: "",
        city: ""
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValues({ ...inputValues, [name]: value });
    }
    const [validation, setValidation] = useState({
        name: "",
        email: "",
        phone_number: "",
        jobTitle: "",
        company: "",
        city: ""
    });
    const checkValidation = () => {
        let errors = JSON.parse(JSON.stringify(validation));
        //name validation
        const alphabetCond = /^[a-zA-Z ]*$/;
        if (!inputValues.name) {
            errors.name = "Full name is required";
        } else if (!inputValues.name.match(alphabetCond)) {
            errors.name = "Please enter valid name";
        } else {
            errors.name = "";
        }
        // email validation
        const emailCond = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!inputValues.email) {
            errors.email = "Email is required";
        } else if (!inputValues.email.match(emailCond)) {
            errors.email = "Please enter valid email address";
        } else {
            errors.email = "";
        }
        //number validation
        const numberCond = /^[6-9]\d{9}$/;
        if (!inputValues.phone_number) {
            errors.phone_number = "Number is required";
        } else if (!inputValues.phone_number.match(numberCond)) {
            errors.phone_number = "Please enter 10 digit phone number";
        } else {
            errors.phone_number = "";
        }
        //college validation
        if (!inputValues.jobTitle) {
            errors.jobTitle = "Job title is required";
        } else {
            errors.jobTitle = "";
        }
        //address validation
        if (!inputValues.company) {
            errors.company = "Company is required";
        } else {
            errors.company = "";
        }

        //city validation
        if (!inputValues.city) {
            errors.city = "City is required";
        } else {
            errors.city = "";
        }
        //zipcode validation
        setValidation(errors);

        if (errors.name === '' && errors.email === '' && errors.phone_number === '' && errors.jobTitle === '' && errors.company === '' && errors.city === '') {
            return true;
        } else {
            return false;
        }
    };
    const sponsorForm = (e) => {
        e.preventDefault();
        setResultMessage('');
        const isSuccess = checkValidation();
        if (isSuccess) {
            const contactData = new FormData();
            contactData.append('type', 'sponsor');
            contactData.append('name', inputValues.name);
            contactData.append('phone_number', inputValues.phone_number);
            contactData.append('email', inputValues.email);
            contactData.append('job_title', inputValues.jobTitle);
            contactData.append('company', inputValues.company);
            contactData.append('city', inputValues.city);
            const params = {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            };
            axios.post(`${appConstant.API_URL}/c/sponsor_leads`, contactData, params)
                .then(res => {
                    if (res?.data?.status === 200) {
                        setShowPdf(true);
                        setResultMessage('')
                    } else {
                        setResultMessage(res?.data?.message);
                        setShowPdf(false);
                    }
                })
                .catch(error => {
                });
        }
    }
    const isMobile = window.innerWidth <= 768;
    // const fileUrl = 'assets/pdf/1.pdf';
    const fileUrl = 'assets/pdf/1.pdf';

    const [getSEO, setGetSEO] = useState([]);

    const params = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }

    const getSEOData = () => {
        axios
          .get(`${appConstant.API_URL}/c/page_meta/sponsor`, params)
          .then((res) => {
            if (res.data.status === 200) {
                setGetSEO(res.data.data);
            }
          })
          .catch((error) => {
          });
    };
    useEffect(() => {
        getSEOData();
    },[]);
    return (
        <>
            <Helmet>
                <title>{getSEO?.meta_title || 'Sponsor'}</title>
                <meta name="description" content={getSEO?.meta_description} />
                <meta name="keywords" content={getSEO?.meta_keyword} />
                <meta
                    property="og:title"
                    content={getSEO?.meta_title}
                />
                <meta
                    property="og:image"
                    content={`${appConstant.CDN_URL}/assets/images/logo-black.png`}
                />
            </Helmet>
            <ScrollToTop />
            <Header />
            <section className='tickets d-block internal-page'>
                <h5 className='cursive-heading'>become a</h5>
                <h3 className='heading'>sponsor</h3>
                <p className='paragraph'>Join Jashnaa, Agra's culinary hub, and elevate your food and beverage brand. Partnering with us means reaching a captivated audience, showcasing your expertise, and maximizing exposure through our marketing channels. Connect with industry leaders and enthusiasts alike while becoming a cornerstone of Agra's culinary scene. Let's create culinary magic together!</p>

                <p className='personal-detail'>
                    {/* <span className='orange-text'>Nivisha Sinha</span> <br /> */}
                    Team Jashnaa <br />
                    <span className='orange-text'>Email:</span> {`${appConstant.SALE_EMAIL_URL}`} <br />
                    <span className='orange-text'>Phone (WhatsApp):</span> +91 {`${appConstant.CONTACT_URL}`} <br />
                </p>

                <Link to='/contact-us' className='orange-button button-small d-inline-block'><img className='button-icon' src={`${appConstant.CDN_URL}/assets/images/plane.png`} alt="icons" />GET IN TOUCH</Link>
                <br/><br/>
                {/* <ul className='footer-links'>
                    <li className='icons'><a href={`${appConstant.FACEBOOK_URL}`} target='_blank' rel="noreferrer"><img src={`${appConstant.CDN_URL}/assets/images/facebook.png`} alt='bg' /></a></li>
                    <li className='icons'><a href={`${appConstant.TWITTER_URL}`} target='_blank' rel="noreferrer"><img src={`${appConstant.CDN_URL}/assets/images/twitter.png`} alt='bg' /></a></li>
                    <li className='icons'><a href={`${appConstant.INSTAGRAM_URL}`} target='_blank' rel="noreferrer"><img src={`${appConstant.CDN_URL}/assets/images/instagram.png`} alt='bg' /></a></li>
                    <li className='icons'><a href={`${appConstant.YOUTUBE_URL}`} target='_blank' rel="noreferrer"><img src={`${appConstant.CDN_URL}/assets/images/youtube.png`} alt='bg' /></a></li>
                </ul> */}
                {!showPdf ? <form onSubmit={(e) => { sponsorForm(e) }}>
                    <h1 className='text-center my-4'><strong>Enter your details</strong></h1>
                    {resultMessage && <p className='paragraph text-center ff-bold green-text mb-2'>{resultMessage}</p>}
                    <div className='row'>
                        <div className='col-sm-6 form-mb'>
                            <div className='input-groups'>
                                <label className='contact-label'><img className='label-icons' src={`${appConstant.CDN_URL}/assets/images/user.png`} alt="icons" /></label>
                                <input className='contact-input' type="text" name="name" placeholder="Name*" value={inputValues.name} onChange={(e) => handleChange(e)} />
                            </div>
                            {validation.name && <p className='paragraph red-text mt-2'>{validation.name}</p>}
                        </div>
                        <div className='col-sm-6 form-mb'>
                            <div className='input-groups'>
                                <label className='contact-label'><img className='label-icons' src={`${appConstant.CDN_URL}/assets/images/email.png`} alt="icons" /></label>
                                <input className='contact-input' type="text" name="email" placeholder="Email Address*" value={inputValues.email} onChange={(e) => handleChange(e)} />
                            </div>
                            {validation.email && <p className='paragraph red-text mt-2'>{validation.email}</p>}
                        </div>
                        <div className='col-sm-6 form-mb'>
                            <div className='input-groups'>
                                <label className='contact-label'><img className='label-icons' src={`${appConstant.CDN_URL}/assets/images/phone.png`} alt="icons" /></label>
                                <input className='contact-input' type="text" name="phone_number" placeholder="Phone*" value={inputValues.phone_number} onChange={(e) => handleChange(e)} />
                            </div>
                            {validation.phone_number && <p className='paragraph red-text mt-2'>{validation.phone_number}</p>}
                        </div>
                        <div className='col-sm-6 form-mb'>
                            <div className='input-groups'>
                                <label className='contact-label'><img className='label-icons' src={`${appConstant.CDN_URL}/assets/images/edit.png`} alt="icons" /></label>
                                <input className='contact-input' type="text" name="jobTitle" placeholder="Job Title*" value={inputValues.jobTitle} onChange={(e) => handleChange(e)} />
                            </div>
                            {validation.jobTitle && <p className='paragraph red-text mt-2'>{validation.jobTitle}</p>}
                        </div>
                        <div className='col-sm-6 form-mb'>
                            <div className='input-groups'>
                                <label className='contact-label'><img className='label-icons' src={`${appConstant.CDN_URL}/assets/images/home.png`} alt="icons" /></label>
                                <input className='contact-input' type="text" name="company" placeholder="company*" value={inputValues.company} onChange={(e) => handleChange(e)} />
                            </div>
                            {validation.company && <p className='paragraph red-text mt-2'>{validation.company}</p>}
                        </div>
                        <div className='col-sm-6 form-mb'>
                            <div className='input-groups'>
                                <label className='contact-label'><img className='label-icons' src={`${appConstant.CDN_URL}/assets/images/city.png`} alt="icons" /></label>
                                <input className='contact-input' type="text" name="city" placeholder="City*" value={inputValues.city} onChange={(e) => handleChange(e)} />
                            </div>
                            {validation.city && <p className='paragraph red-text mt-2'>{validation.city}</p>}
                        </div>
                        <div className='text-center'>
                            <button className='orange-button d-inline-block'><img className='button-icon' src={`${appConstant.CDN_URL}/assets/images/plane.png`} alt="icons" />Submit</button>
                        </div>
                    </div>
                </form> : <>
                {!isMobile ? (
            <iframe allowfullscreen="allowfullscreen" scrolling="no" class="fp-iframe" src="https://heyzine.com/flip-book/7bf7ee6f07.html" style={{border: "1px solid lightgray", width: "100%", height: "900px"}}></iframe>
          ) : (
            <div>
              <p>PDFs can't be displayed directly on this device. Please click the button below to view it.</p>
              <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                Open PDF
              </a>
            </div>
          )}
                </>}
            </section>
            <Footer />
        </>
    );
}
export default Sponsor;