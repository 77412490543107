import React, { useState, useEffect } from 'react';
import Header from './shared/Header';
import Footer from './shared/Footer';
import ScrollToTop from './shared/ScrollToTop';
import Timer from './shared/Timer';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import axios from 'axios';
import appConstant from './shared/constant/constant.json';
import Loader from './shared/Loader';
import {Helmet} from "react-helmet";

function Home() {
    const [youtube, setYoutube] = useState([]);
    const [ticket, setTicket] = useState([]);
    const [sponsors, setSponsors] = useState([]);
    const [isLoader, setIsLoader] = useState(true);
    const [isYoutubeLoader, setIsYoutubeLoader] = useState(true);
    const [isSponsorsLoader, setIsSponsorsLoader] = useState(true);
    const [getSEO, setGetSEO] = useState([]);

    const params = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }

    const getSEOData = () => {
        axios
          .get(`${appConstant.API_URL}/c/page_meta/home`, params)
          .then((res) => {
            if (res.data.status === 200) {
                setGetSEO(res.data.data);
            }
          })
          .catch((error) => {
          });
    };

    // youtube api
    const getYouTubeData = () => {
        axios.get(`${appConstant.API_URL}/c/youtube`, params).then(res => {
              setIsYoutubeLoader(false);
            if (res?.data?.status === 200) {
                setYoutube(res?.data?.data);
            } else {
                setYoutube([]);
            }
        })
        .catch(error => {
              setIsYoutubeLoader(true);
        });
    }
    // ticket api
    const getTicketData = () => {
        axios.get(`${appConstant.API_URL}/c/ticket`, params).then(res => {
              setIsLoader(false);
            if (res?.data?.status === 200) {
                setTicket(res?.data?.data);
            } else {
                setTicket([]);
            }
        })
        .catch(error => {
              setIsLoader(true);
        });
    }
    // sponsors
    const getSponsorsData = () => {
        axios.get(`${appConstant.API_URL}/c/sponsors`, params).then(res => {
              setIsSponsorsLoader(false);
            if (res?.data?.status === 200) {
                setSponsors(res?.data?.data);
            } else {
                setSponsors([]);
            }
        })
        .catch(error => {
              setIsSponsorsLoader(true);
        });
    }
    useEffect(() => {
        getSponsorsData();
        getYouTubeData();
        getTicketData();
        getSEOData();
    },[]);
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 2000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 1999, min: 1200 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1199, min: 992 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 991, min: 768 },
            items: 2
        },
        smallMobile: {
            breakpoint: { max: 767, min: 0 },
            items: 2
        }
    };

    const currentDate = new Date();
    const targetDate = new Date(2024, 11, 14); // Month is 0-indexed, so September is 8
    const duration = targetDate.getTime() - currentDate.getTime();
    return (
        <>
            <Helmet>
            <title>{getSEO?.meta_title || 'Home'}</title>
            <meta name="description" content={getSEO?.meta_description} />
            <meta name="keywords" content={getSEO?.meta_keyword} />
            <meta
                property="og:title"
                content={getSEO?.meta_title}
            />
            <meta
                property="og:image"
                content={`${appConstant.CDN_URL}/assets/images/logo-black.png`}
            />
            </Helmet>
            <ScrollToTop />
            <Header />
            <section className='main-banner' id='home'>
                <img className="main-image" src={`${appConstant.CDN_URL}/assets/images/main-banner.png`} alt='bg' />
                <div className='banner-overlay'>
                    <h3 className='heading'>Jashnaa 2024</h3>
                    <Timer duration={duration} />
                    <HashLink to="/#buy-tickets" className='orange-button'>BUY TICKETS</HashLink>
                </div>
            </section>
            <section className='section-2 mb-3' id='jashnaa-2024'>
                <h3 className='heading'>JASHNAA 2024 <br />14th - 15th DECEMBER</h3>
                <div className='section-2-flex'>
                    <div className='section-2-column'>
                        <img className='section-2-image' src={`${appConstant.CDN_URL}/assets/images/section-2.png`} alt='poster' />
                    </div>
                    <div className='section-2-column'>
                        <p className='section-2-paragraph'>Jashnaa is more than just a music and food festival—it's a vibrant celebration of culture, creativity, and community. With an unwavering commitment to curating unforgettable experiences, Jashnaa brings together the finest in music, culinary delights, and cultural immersion. Our journey begins in Agra, where we proudly launch our inaugural event on {appConstant.EVENT_DATE}. Join us for an extraordinary fusion of sights, sounds, and flavors as we embark on this thrilling adventure together.</p>
                        <p className='section-2-paragraph mb-0'>THEME FOR THIS YEAR:</p>
                        <p className='section-2-paragraph mb-0 orange-text ff-bold'>STREETS TO SUITES: AGRA'S CULINARY JOURNEY</p>
                    </div>
                    <div className='section-2-column-2'>
                        <div className='column-2-column'>
                            <h5 className='paragraph ff-bold'>Tickets Available</h5>
                            <h5 className='column-count mb-0'>3000</h5>
                            {/* <p className='paragraph mb-0'>People, we’re still waiting</p> */}
                        </div>
                        <div className='column-2-column'>
                            <h5 className='paragraph ff-bold'>Tickets Sold</h5>
                            <h5 className='column-count mb-0'> 850</h5>
                            {/* <p className='paragraph mb-0'>Already bought tickets.</p> */}
                        </div>
                    </div>
                </div>
            </section>
            {isYoutubeLoader ?
                <Loader/>
                :
                <>
                    {youtube.length > 0 && 
                        <section className='video-section' id='music'>
                            <div id="video-carousel" className="carousel slide">
                                <div className="carousel-indicators">
                                    {youtube.map((res, i) => {
                                        return (
                                            <button type="button" data-bs-target="#video-carousel" data-bs-slide-to={i} className={i === 0 ? "active" : ''}></button>
                                        )
                                    })}
                                </div>
                                <div className="carousel-inner">
                                    {youtube.map((res, i) => {
                                        return (
                                            <div className={i === 0 ? "carousel-item active" : "carousel-item"} key={'youtube' + i}>
                                                <iframe className='video-iframe' src={"https://www.youtube.com/embed/" + res.youtube_id} title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen={true}></iframe>
                                            </div>
                                        )
                                    })}
        
                                </div>
                            </div>
                        </section>                        
                    }
                </>
            }
            <section className='aboutus'>
                <h5 className='cursive-heading' id='food-and-drink'>what is</h5>
                <h3 className='heading'>jashnaa</h3>
                <p className='paragraph'>Where the Ultimate Party of Culture and Cuisine Unfolds, and Every Bite Spins a Tale!</p>
                <div className='aboutus-flex'>
                    <Link to='artists' className='aboutus-column'>
                        <img className='about-image' src={`${appConstant.CDN_URL}/assets/images/food1.png`} alt="food" />
                        <div className='about-overlay'>
                            <h6 className='about-title'>Our Favorite Artists</h6>
                            <h6 className='about-content'>on the festival will</h6>
                        </div>
                    </Link>
                    <Link to='treats' className='aboutus-column'>
                        <img className='about-image' src={`${appConstant.CDN_URL}/assets/images/food2.png`} alt="food" />
                        <div className='about-overlay'>
                            <h6 className='about-title'>Our Favorite Treats</h6>
                            <h6 className='about-content'>on the festival will</h6>
                        </div>
                    </Link>
                    <Link to='drinks' className='aboutus-column'>
                        <img className='about-image' src={`${appConstant.CDN_URL}/assets/images/food3.png`} alt="food" />
                        <div className='about-overlay'>
                            <h6 className='about-title'>Our Favorite Drinks</h6>
                            <h6 className='about-content'>on the festival will</h6>
                        </div>
                    </Link>
                </div>
            </section>
            <section className='food'>
                <div className='food-column'>
                    <h5 className='cursive-heading'>we collaborate</h5>
                    <h3 className='heading'>meet our sponsors </h3>
                    {/* <p className='paragraph'>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.</p> */}
                </div>
                <div className='food-column'>
                {isSponsorsLoader ?
                <Loader/>
                :

                    <Carousel responsive={responsive} infinite={true} autoPlay={true} arrows={false} showDots={true}>
                        {sponsors.map((res, i)=>                          
                            <div className='brands' key={'sponsorsParent' + i}>
                                {res.map((result, m)=>
                                    <img className='brand-img' src={result.image} alt="brand" key={'sponsorChild' + i + m}/>
                                )}
                            </div>                            
                        )}
                       
                    </Carousel>
                }
                </div>

            </section>
            <section className='schedual' id='schedual'>
                <h5 className='cursive-heading text-center'>get your</h5>
                <h3 className='heading text-center'>SCHEDULE</h3>
                <div className='schedual-flex'>
                    <div className='schedual-column text-start position-relative'>
                        <img className='schedual-image' src={`${appConstant.CDN_URL}/assets/images/schedual.webp`} alt='schedual' />
                    </div>
                    <div className='schedual-column'>
                        <p className='paragraph'>Rock Out with Our Stellar Music Lineup: Check Out the Schedule of Sensational Artists!</p>
                        <div className='row mt-lg-5 mt-4 mt-sm-4'>
                            <div className='col-sm-6'>
                                <ul className="connected-list">
                                    {/* <li className="list-item first-item">
                                        <h6 className='list-title'>Friday</h6>
                                    </li> */}
                                    {/* <li className="list-item">
                                        <h6 className='event-name'>01:00 PM <br /> LOREUM IPSUM</h6>
                                        <p className='event-desc'>Lorem ipsum dolor sit amet,</p>
                                    </li>
                                    <li className="list-item">
                                        <h6 className='event-name'>03:00 PM <br /> LOREUM IPSUM</h6>
                                        <p className='event-desc'>Lorem ipsum dolor sit amet,</p>
                                    </li>
                                    <li className="list-item">
                                        <h6 className='event-name'>05:00 PM <br /> LOREUM IPSUM</h6>
                                        <p className='event-desc'>Lorem ipsum dolor sit amet,</p>
                                    </li> */}
                                    <li className="list-item first-item">
                                        <h6 className='list-title'>14 December</h6>
                                    </li>
                                </ul>
                            </div>
                            <div className='col-sm-6'>
                                <ul className="connected-list">
                                    {/* <li className="list-item first-item">
                                        <h6 className='list-title'>saturday</h6>
                                    </li> */}
                                    {/* <li className="list-item">
                                        <h6 className='event-name'>09:00 AM <br /> LOREUM IPSUM</h6>
                                        <p className='event-desc'>Lorem ipsum dolor sit amet,</p>
                                    </li>
                                    <li className="list-item">
                                        <h6 className='event-name'>02:00 PM <br /> LOREUM IPSUM</h6>
                                        <p className='event-desc'>Lorem ipsum dolor sit amet,</p>
                                    </li>
                                    <li className="list-item">
                                        <h6 className='event-name'>04:00 PM <br /> LOREUM IPSUM</h6>
                                        <p className='event-desc'>Lorem ipsum dolor sit amet,</p>
                                    </li> */}
                                     <li className="list-item first-item">
                                        <h6 className='list-title'>15 December</h6>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='buy-tickets' id='buy-tickets'>
                <h5 className='cursive-heading text-center'>our tickets</h5>
                <h3 className='heading text-center'>Hurry Up To Buy Tickets</h3>

                <div className='tickets-flex-2'>
                    <div className='tickets-flex-2-columns'>
                        <h6 className='ticket-price'>
                            DATE<br />
                            <span>{appConstant.EVENT_DATE}</span>
                        </h6>
                    </div>
                    <div className='tickets-flex-2-columns'>
                        <h6 className='ticket-price'>
                            TIME<br />
                            <span>11AM - 11:30PM</span>
                        </h6>
                    </div>
                    <div className='tickets-flex-2-columns'>
                        <h6 className='ticket-price'>
                            LOCATION<br />
                            <span>AGRA</span>
                        </h6>
                    </div>
                </div>
                {isLoader ? (
                      <Loader />
                      ) : (
                      <>
                    {ticket.length > 0 && 
                    <div className='tickets-flex'>
                        {ticket.map((res, i)=>{
                            return (
                                <div className='tickets-columns' key={'ticket' + i}>
                                    <div>
                                        <h6 className='paragraph orange-text ff-bold title tt-uppercase'>{res.title}</h6>
                                        <h5 className='ticket-price'>INR {res.price}/DAY</h5>
                                        {res.benefits?.length > 0 && res.benefits?.map((result, index)=>{
                                            return(
                                                <h6 key={index} className='paragraph'>{result}</h6>
                                            )
                                        })
                                        }
                                        
                                    </div>
                                    <div className='text-center'>
                                        <Link to='/buy-tickets' className='orange-button d-inline-block'>BUY NOW</Link>
                                    </div>
                                </div>
                            )
                        })}                  
                    </div>
                    }
                    </>
                )}
            </section>
            <section className='contactus'>
                <h5 className='cursive-heading'>contact us</h5>
                {/* <h3 className='heading'>GET IN TOUCH</h3> */}
                <div className='contact-flex'>
                    <div className='contact-column'>
                        <h3 className='heading'>GET IN TOUCH</h3>
                        {/* <p className='paragraph'>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.</p> */}
                    </div>
                    <div className='contact-column'>
                        <div className='contact-flex-2'>
                            <div className='contact-column-2'>
                                <img className='icons' src={`${appConstant.CDN_URL}/assets/images/mobile.png`} alt="icons" />
                                <p className='paragraph ff-bold contact-head'>Contact us for participating with us
                                </p>
                                <a className='paragraph td-underline' href={`mailto:${appConstant.SALE_EMAIL_URL}`}>{`${appConstant.SALE_EMAIL_URL}`}</a>
                                <p className='paragraph ff-bold'>
                                    +91 {`${appConstant.CONTACT_URL}`}
                                </p>
                            </div>
                            <div className='contact-column-2'>
                                <img className='icons' src={`${appConstant.CDN_URL}/assets/images/mobile.png`} alt="icons" />
                                <p className='paragraph ff-bold contact-head'>Contact us to buy the tickets</p>
                                <a className='paragraph td-underline' href={`mailto:${appConstant.INFO_EMAIL_URL}`}>{`${appConstant.INFO_EMAIL_URL}`}</a>
                                <p className='paragraph ff-bold'>
                                    +91 {`${appConstant.CONTACT_URL}`}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}
export default Home;