import React, { useState, useEffect } from 'react';

const Timer = ({ duration }) => {
    const [time, setTime] = useState(duration);

    useEffect(() => {
        if (time > 0) {
            const timer = setTimeout(() => {
                setTime(prevTime => Math.max(prevTime - 1000, 0)); // Ensure time doesn't go below 0
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [time]);

    const setFormattedTime = (milliseconds) => {
        let total_seconds = parseInt(Math.floor(milliseconds / 1000));
        let total_minutes = parseInt(Math.floor(total_seconds / 60));
        let total_hours = parseInt(Math.floor(total_minutes / 60));
        let days = parseInt(Math.floor(total_hours / 24));

        let seconds = parseInt(total_seconds % 60);
        let minutes = parseInt(total_minutes % 60);
        let hours = parseInt(total_hours % 24);

        return (
            <div className="counter-flex"> 
                <h5 className="counter-time">{days} <br/> <span className="counter-days"> DAYS</span> </h5> 
                <h5 className="counter-time"> : </h5>
                <h5 className="counter-time">{hours} <br/> <span className="counter-days"> HOURS</span> </h5> 
                <h5 className="counter-time"> : </h5>
                <h5 className="counter-time">{minutes} <br/> <span className="counter-days"> MINUTES</span> </h5> 
                <h5 className="counter-time"> :</h5>
                <h5 className="counter-time">{seconds} <br/> <span className="counter-days"> SECONDS</span> </h5> 
            </div>
        );
    }

    return (
        <div>
            {setFormattedTime(time)}
        </div>
    );
}

export default Timer;