import React from 'react';
import { Link } from 'react-router-dom';

function Card(props) {
    return (
        <>
        {props?.url ?
            <Link className='flex-cards' to={props.url} target='_blank' key={props.key}> 
                <img className='card-image' src={props.image} alt={props.title}/>
                <div className='flex-overlay'>
                    {props.title ? <h5 className='flex-title'>{props.title}</h5> : null }
                    {/* {props.description ? <p className='flex-content'>{props.description}</p> : null } */}
                </div>
            </Link>
        :
        <div className='flex-cards' key={props.key}> 
            <img className='card-image' src={props.image} alt={props.title}/>
            <div className='flex-overlay'>
                {props.title ? <h5 className='flex-title'>{props.title}</h5> : null }
                {/* {props.description ? <p className='flex-content'>{props.description}</p> : null } */}
            </div>
        </div>
        }
        </>
    );
}
export default Card;