import React, { useState, useEffect } from 'react';
import Header from './shared/Header1';
import Footer from './shared/Footer1';
import ScrollToTop from './shared/ScrollToTop';
import appConstant from './shared/constant/constant.json';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Loader from './shared/Loader';
function PaymentMessage() {
    const { orderId } = useParams();
    const [isLoader, setIsLoader] = useState(false);
    const [order, setOrder] = useState([]);
    const params = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*"
    };
    const getOrderData = () => {
        setIsLoader(true);
        axios.get(`${appConstant.API_URL}/c/order/${orderId}`, params).then(res => {
            if (res?.data?.status === 200) {
                setOrder(res?.data?.data);
                setIsLoader(false);
            } else {
                setIsLoader(false);
                setOrder([]);
            }
        })
            .catch(error => {
                setIsLoader(false);
            });
    }
    useEffect(() => {
        getOrderData();
    }, []);
    return (
        <>
        <ScrollToTop/>
        <Header/>
        {isLoader ? (
                                <Loader />
                            ) : (
        <section className='tickets d-block internal-page'>
                <img className='payment-icon' src={`${appConstant.CDN_URL}/assets/images/success.png`} alt='bg'/>
                <h3 className='heading text-center mb-0'>PAYMENT CONFIRMED</h3>
                <p className='paragraph text-center ff-bold'>Your reservation is confirmed. We'll send you an email with reservation and payment details</p> 
                <div className='text-center button-top'>
                    <Link className='orange-button' to={'/contact-us'}><img className='button-icon' src={`${appConstant.CDN_URL}/assets/images/plane.png`} alt='icon' />GET IN TOUCH</Link>
                </div>  
                <div className='payment-details'>
                    <div className='ticket-detail-card'>
                        <div className='detail-card-flex'>
                            <div>
                                <h6 className='paragraph ff-bold tt-uppercase mb-0 theme-blue-text'>{order.ticket_name}</h6>
                                <h6 className='paragraph mb-0'><small>{order.number_of_tickets} Ticket(s)</small></h6>
                            </div>
                            <div>
                                <h6 className='paragraph ff-bold tt-uppercase mb-0 orange-text'>#{parseInt(order.id) + 1000}</h6>
                            </div>
                        </div>
                        <div className='detail-card-flex'>
                            <div>
                                <h6 className='paragraph mb-0'>
                                {order.ticket_date}<br/>
                                    12PM - 11PM<br/>
                                    Agra
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div className='ticket-detail-card border-transparent'>
                        <div className='detail-card-flex'>
                            <div>
                                <h6 className='paragraph mb-0'>Sub Total:</h6>
                            </div>
                            <div>
                                <h6 className='paragraph mb-0'>INR {order.sub_total}</h6>
                            </div>
                        </div>
                        {order.order_amount && <div className='detail-card-flex'>
                            <div>
                                <h6 className='paragraph mb-0'>Discount:</h6>
                            </div>
                            <div>
                                <h6 className='paragraph mb-0'>INR {order.discount}</h6>
                            </div>
                        </div>
                        }
                        <div className='detail-card-flex'>
                            <div>
                                <h6 className='paragraph mb-0 ff-bold theme-bold-text'>Total Amount:</h6>
                            </div>
                            <div>
                                <h6 className='paragraph mb-0  ff-bold theme-bold-text'>INR {order.order_amount}</h6>
                            </div>
                        </div>
                    </div>                  
                </div>  
        </section>
        )}
        <Footer/>
        </>
    );
}
export default PaymentMessage;