import React from 'react';
import { Link } from 'react-router-dom';
import appConstant from './constant/constant.json';

function Footer() {
    return (
        <footer className='footer footer-1'>
            <Link className='links' to='/'><img className='footer-logo' src={`${appConstant.CDN_URL}/assets/images/logo.png`} alt='jashnaa'/></Link>
            <ul className='footer-links'>
                <Link className='links' to='/'>HOME</Link> 
                {/* <Link className='links' to='/'>PLAN YOUR VISIT</Link>  */}
                <Link className='links' to='/buy-tickets'>TICKETS</Link> 
                <Link className='links' to='/contact-us'>CONTACT US</Link> 
            </ul>
            <ul className='footer-links'>
                <li className='icons'><a href={`${appConstant.FACEBOOK_URL}`} target='_blank' rel="noreferrer"><img src={`${appConstant.CDN_URL}/assets/images/facebook.png`} alt='bg'/></a></li>
                <li className='icons'><a href={`${appConstant.TWITTER_URL}`} target='_blank' rel="noreferrer"><img src={`${appConstant.CDN_URL}/assets/images/twitter.png`} alt='bg'/></a></li>
                <li className='icons'><a href={`${appConstant.INSTAGRAM_URL}`} target='_blank' rel="noreferrer"><img src={`${appConstant.CDN_URL}/assets/images/instagram.png`} alt='bg'/></a></li>
                <li className='icons'><a href={`${appConstant.YOUTUBE_URL}`} target='_blank' rel="noreferrer"><img src={`${appConstant.CDN_URL}/assets/images/youtube.png`} alt='bg' /></a></li>
            </ul>
            
            <p className='copyright'>© 2024 JASHNAA All Rights Reserved.</p>
        </footer>
    );
}
export default Footer;